// Libraries
import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { pdfjs } from 'react-pdf';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer, toast } from 'react-toastify';

// Styles
import './styles/App.css';
import './styles/modalStyles.css';
import './styles/formStyles.css';
import './styles/flexStyling.css';
import './styles/header.css';
import './styles/pageStructure.css';
import './styles/cards.css';

// Components
import HeaderBar from './components/HeaderBar';
import FooterBar from './components/FooterBar';
import LogUserIn from './components/UserComponents/logUserIn';
import IdleModal from './components/Modals/IdleModal';
import ModalMimicPage from './components/PageStructure/modalMimicPage.js';
import GeneralModal from './components/Modals/GeneralModal.js';
import UserManager from './components/UserManager';

// Pages
import ViewScriptDetail from './pages/sceneAndShotlistView/scriptView.js';
import UserDashboard from './pages/userDashboard/userDashboard.js';
import DownloadSample from './pages/downloadSample';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsOfUse from './pages/termsOfUse';
import ExternalPage from './pages/externalPage';
import Profile from './pages/profile';
//      Welcome User Pages
import WelcomeUserMain from './pages/welcomeUser/newUserWelcome.js';
import WelcomeUserFooter from './pages/welcomeUser/newUserWelcomeFooter.js';
import FreeTrialActivated from './pages/welcomeUser/freeTrialActivated.js';
//       Script Pages
import SecureScriptFooter from './pages/createProject/secureScriptFooter.js';
import SelectScriptType from './pages/createProject/selectScriptType.js';
import UploadScriptBox from './pages/createProject/uploadScript.js';
import CreateManualProject from './pages/createProject/createManualProject.js';
//       Admin Pages
import AdminUser from './admin/pages/user';
import AdminSubs from './admin/pages/subscriptions';

// Setup Application Contexts
import { SessionProvider } from './context/Session';
import { UserProvider } from './context/User';
import { UserContext } from './context/User';

// Setup Font Awesome
library.add(faTimes);
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  // Setup Auth Data and Functionality
  const { isLoading } = useAuth0();

  // Setup State Data
  const { user } = useContext(UserContext);
  const [scriptData, setScriptData] = useState(null);
  const [isIdleOpen, setIsIdleOpen] = useState(false);
  const uploadedFile = scriptData ? scriptData.file : null;
  const [isUserManagerReady, setUserManagerReady] = useState(false);
  const [loadScreen, setLoadScreen] = useState(false);

  const RerouteSample = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/DownloadSample');
    }, []);

    return <div></div>;
  };

  const handleUserManagerReady = () => {
    console.log('Ready');
    setLoadScreen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserProvider>
        <SessionProvider>
          <Router>
            <UserManager callRender={handleUserManagerReady} />

            {!isLoading ? (
              <>
                <ToastContainer
                  position="bottom-left"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <IdleModal isOpen={isIdleOpen} onRequestClose={setIsIdleOpen} />
                <HeaderBar />
                <div className="pageHolder">
                  {loadScreen ? (
                    <Routes>
                      {/* ========= GENERIC PAGES =========== */}
                      <Route path="/DownloadSample" element={<DownloadSample />} />
                      <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
                      <Route exact path="/termsOfUse" element={<TermsOfUse />} />

                      {/* ========= AUTH PAGES =========== */}
                      <Route path="/login" element={<LogUserIn />} />

                      {/* ========= NEW USER FLOWS =========== */}
                      <Route
                        exact
                        path="/app/newUserWelcome"
                        element={
                          <ModalMimicPage
                            page={<WelcomeUserMain />}
                            footer={<WelcomeUserFooter />}
                          />
                        }
                      />
                      <Route
                        exact
                        path="/app/freeTrialActivated"
                        element={
                          <ModalMimicPage
                            page={<FreeTrialActivated />}
                            footer={<SecureScriptFooter />}
                          />
                        }
                      />

                      {/* ========= CREATE SCRIPT FLOWS =========== */}
                      <Route
                        exact
                        path="/app/newProject/1"
                        element={
                          <ModalMimicPage
                            page={<SelectScriptType />}
                            footer={<SecureScriptFooter />}
                            close={`/app/home`}
                            width="800"
                          />
                        }
                      />
                      <Route
                        exact
                        path="/app/newProject/2"
                        element={
                          <ModalMimicPage
                            page={<UploadScriptBox />}
                            footer={<SecureScriptFooter />}
                            close={`/app/home`}
                            back={true}
                            width="800"
                          />
                        }
                      />
                      <Route
                        exact
                        path="/app/newManualProject/2"
                        element={
                          <ModalMimicPage
                            page={<CreateManualProject />}
                            footer={<SecureScriptFooter />}
                            close={`/app/home`}
                            back={true}
                            width="800"
                          />
                        }
                      />

                      {/* ========= STANDARD FLOWS =========== */}
                      <Route
                        exact
                        path="/"
                        element={<ExternalPage page="https://www.shotkraft.com" />}
                      />
                      <Route exact path="/app/home" element={<UserDashboard />} />
                      <Route exact path="/app/profile" element={<Profile />} />
                      <Route
                        exact
                        path="/app/shotList/:scriptId"
                        element={<ViewScriptDetail uploadedFile={uploadedFile} />}
                      />

                      {/* ========= ADMIN PAGES =========== */}
                      <Route path="/so/f0OoXTviG/*" element={<RerouteSample />} />
                      {1 ? ( //user && user.isStaff ? (
                        <>
                          <Route path="/admin/users" element={<AdminUser />} />
                          <Route path="/admin/subscriptions" element={<AdminSubs />} />
                          <Route
                            path="/admin/scripts/shotList/:scriptId"
                            element={<ViewScriptDetail uploadedFile={uploadedFile} />}
                          />
                        </>
                      ) : null}

                      {/* ========= OTHER PAGES =========== */}
                      <Route path="/health" element={<h3>ShotList is Healthy!</h3>} />
                    </Routes>
                  ) : null}
                </div>
                <FooterBar />
              </>
            ) : null}
          </Router>
          <GeneralModal />
        </SessionProvider>
      </UserProvider>
    </LocalizationProvider>
  );
}

export default App;
