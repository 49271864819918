const headers = (method, body) => {
  let header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  if (body) {
    header['Content-Type'] = 'application/json';
    return {
      method: method,
      headers: header,
      body: JSON.stringify(body),
    };
  } else {
    return {
      method: method,
      headers: header,
    };
  }
};

const adminUserService = {
  getAllUsers: async function (parameters) {
    try {
      console.log('parameters', parameters);
      let url = `${process.env.REACT_APP_API_URL}/users/staff/fetchUsers`;
      const response = await fetch(url, headers('POST', parameters));
      if (response.ok) {
        const users = await response.json();
        return users;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getUserHistory: async function (userId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/staff/fetchUserHistory/${userId}`;
      const response = await fetch(url, headers('GET'));
      if (response.ok) {
        const history = await response.json();
        return history;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getUserScripts: async function (userId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/staff/fetchUserScripts/${userId}`;
      const response = await fetch(url, headers('GET'));
      if (response.ok) {
        const scripts = await response.json();
        return scripts;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getUserStats: async function (timeframe, type) {
    try {
      let parameters = { timeframe: timeframe, type: type };
      let url = `${process.env.REACT_APP_API_URL}/users/staff/fetchUserCounts`;
      const response = await fetch(url, headers('POST', parameters));
      if (response.ok) {
        const count = await response.json();
        return count;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
};

export default adminUserService;
