import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { Tooltip } from '@mui/material';
import { Drawer } from '@mui/material';
import { Box, Select, MenuItem, TextField, Button, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrashAlt, faPlus, faEye } from '@fortawesome/free-solid-svg-icons';

// Import Services
import AdminUserSvc from '../services/adminUserService';
import AdminUtils from '../services/utils';

// Import Components
import UserInfo from '../components/user/userInfo';
import StatIgnoreModal from '../../components/Modals/statIgnore';
import ManageCreditsModal from '../../components/Modals/manageCredits';
import AdminCancelSubscriptionModal from '../components/subscriptions/adminCancelSubscription';
import UpgradeMembership from '../../components/Modals/upgradeMembership.js';

function AdminUserPage() {
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [sortModel, setSortModel] = useState([]);
  const [criteria, setCriteria] = useState({
    limit: 25,
    offset: 0,
    sort: { field: 'last_login', direction: 'DESC' },
    search: {},
  });
  const [userStats, setUserStats] = useState({});
  const [filters, setFilters] = useState([]);
  const [currentSearchByStat, setCurrentSearchByStat] = useState('allRegistered');
  const [displaySearch, setDisplaySearch] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const getUserList = async () => {
    if (!criteria.search['stat_ignore']) {
      criteria.search['stat_ignore'] = { value: '', type: 'IS NULL' };
    }
    const userList = await AdminUserSvc.getAllUsers(criteria);
    const usersWithActions = userList.users.map((user) => ({
      ...user,
      selectedAction: '',
    }));
    setUsers(usersWithActions);
    setTotalRows(userList.totalCount);
  };

  const getUserStats = async () => {
    const stats = {
      allActive: await AdminUserSvc.getUserStats('all', 'active'),
      allRegistered: await AdminUserSvc.getUserStats('all', 'registered'),
      allExcluded: await AdminUserSvc.getUserStats('excluded', 'excluded'),
      last30Active: await AdminUserSvc.getUserStats('last30', 'active'),
      last30Registered: await AdminUserSvc.getUserStats('last30', 'registered'),
      mtdActive: await AdminUserSvc.getUserStats('mtd', 'active'),
      mtdRegistered: await AdminUserSvc.getUserStats('mtd', 'registered'),
      todayActive: await AdminUserSvc.getUserStats(null, 'active'),
      todayRegistered: await AdminUserSvc.getUserStats(null, 'registered'),
    };
    setUserStats(stats);
  };

  const handleEyeClick = (user) => {
    setSelectedUser(user);
    setIsPanelOpen(true);
  };

  function searchByStat(field, useDate, searchByStat) {
    useDate.setHours(0, 0, 0, 0);

    let newCriteria = { ...criteria };
    let defaultDate = new Date(new Date().setYear(2020));

    newCriteria.search['created_at'] = { value: defaultDate, type: '>' };
    newCriteria.search['last_login'] = { value: defaultDate, type: '>' };
    newCriteria.search[field].value = useDate;

    if (searchByStat === 'allExcluded') {
      newCriteria.search['stat_ignore'] = { value: '', type: 'LIKE' };
    } else {
      newCriteria.search['stat_ignore'] = { value: '', type: 'IS NULL' };
    }

    setCriteria(newCriteria);
    setCurrentSearchByStat(searchByStat);
  }

  const handleActionChange = async (action, user) => {
    console.log(`Action '${action}' selected for User ID: ${user.id}`, user);
    setSelectedUser(user);

    // Open modal only for Stat Exclude
    if (action === 'Stat Exclude') {
      setOpenModal('StatIgnore');
    } else if (action === 'Cancel') {
      if (!user.subscription_id || user.subscription_end_date) {
        alert('This user does not have a subscription active.');
        return;
      }
      setOpenModal('CancelSubscription');
    } else if (action === 'Credits') {
      setOpenModal('ManageCredits');
    } else if (action === 'Change') {
      // await paymentService.getAvailableCards(userData.id),
      //invoiceDue: await paymentService.checkForPaymentsDue(userData.id),
      setOpenModal('ChangeSubscription');
    } else {
      alert('This option does not work yet. Check back soon!');
    }

    setUsers((prevUsers) =>
      prevUsers.map((u) => (u.id === user.id ? { ...u, selectedAction: 'Pick Action' } : u))
    );
    await getUserList();
  };

  const closeManageCreditModal = () => {
    setOpenModal(false);
    getUserList();
  };

  useEffect(() => {
    if (!openModal) {
      getUserList();
    }
  }, [openModal]);

  const handleStatIgnoreUpdate = (reason) => {
    if (!selectedUser) return;

    setUsers((prevUsers) =>
      prevUsers.map((u) => (u.id === selectedUser.id ? { ...u, stat_ignore: reason } : u))
    );

    setOpenModal(false);
  };

  const updateCriteria = () => {
    const search = {};
    filters.forEach((filter) => {
      search[filter.field] = { value: filter.value, type: filter.operator };
    });
    setCriteria((prev) => ({ ...prev, search }));
  };

  const handleSortChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setCriteria((prev) => ({
        ...prev,
        sort: { field, direction: sort === 'asc' ? 'ASC' : 'DESC' },
      }));
    }
  };

  const handlePaginationChange = (model) => {
    setPaginationModel(model);
    setCriteria((prev) => ({
      ...prev,
      limit: model.pageSize,
      offset: model.page * model.pageSize,
    }));
  };

  const handleAddFilter = () => {
    setFilters((prev) => [
      ...prev,
      { id: Date.now(), field: 'first_name', operator: 'LIKE', value: null },
    ]);
  };

  const handleUpdateFilter = (id, key, value) => {
    setFilters((prev) =>
      prev.map((filter) => (filter.id === id ? { ...filter, [key]: value } : filter))
    );
  };

  const handleRemoveFilter = (id) => {
    setFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  useEffect(() => {
    getUserStats();
    getUserList();
  }, [criteria]);

  useEffect(() => {
    updateCriteria();
  }, [filters]);

  const filterView = () => {
    setFilters([{ id: Date.now(), field: 'first_name', operator: 'LIKE', value: '' }]);
    setDisplaySearch(!displaySearch);
    updateCriteria();
  };

  const columns = [
    {
      field: 'actions',
      headerName: '',
      width: 55,
      renderCell: (params) => (
        <IconButton onClick={() => handleEyeClick(params.row)} title="View">
          <FontAwesomeIcon className="purpleText" icon={faEye} style={{ fontSize: '15px' }} />
        </IconButton>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      filterable: false,
      renderCell: (params) =>
        params.row.stat_ignore ? (
          <Tooltip title={`Excluded from stats because: ${params.row.stat_ignore}`} arrow>
            <span style={{ textDecoration: 'underline dotted', cursor: 'help' }}>
              {params.value}
            </span>
          </Tooltip>
        ) : (
          params.value
        ),
    },
    { field: 'first_name', headerName: 'First Name', width: 150, filterable: false },
    { field: 'last_name', headerName: 'Last Name', width: 150, filterable: false },
    { field: 'email', headerName: 'Email', width: 200, filterable: false },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 150,
      filterable: false,
      valueGetter: (params) => (params ? AdminUtils.FormatDateWithTime(params) : 'N/A'),
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      width: 150,
      filterable: false,
      valueGetter: (params) => (params ? AdminUtils.FormatDateWithTime(params) : 'N/A'),
    },
    {
      field: 'about_us',
      headerName: 'Found By',
      width: 150,
      filterable: false,
    },
    {
      field: 'about_us_text',
      headerName: 'Comments',
      width: 200,
      filterable: false,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'script_count',
      headerName: 'Projects Started',
      width: 100,
      filterable: false,
    },
    {
      field: 'product_name',
      headerName: 'Plan',
      width: 150,
      filterable: false,
      renderCell: (params) => {
        const { subscription_end_date, product_name } = params.row;
        return subscription_end_date ? (
          <Tooltip title={`Ends: ${AdminUtils.FormatDateWithTime(subscription_end_date)}`} arrow>
            <span style={{ color: 'red', fontWeight: 'bold', cursor: 'help' }}>{product_name}</span>
          </Tooltip>
        ) : (
          product_name
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Select
          value={params.row.selectedAction || 'Pick Action'}
          onChange={(e) => handleActionChange(e.target.value, params.row)}
          size="small"
          fullWidth
        >
          <MenuItem value="Pick Action">Pick Action</MenuItem>
          <MenuItem value="Credits">Credit</MenuItem>
          <MenuItem value="Change">Change</MenuItem>
          <MenuItem value="Cancel">Cancel</MenuItem>
          <MenuItem value="Discount">Discount</MenuItem>
          <MenuItem value="Delete">Delete</MenuItem>
          <MenuItem value="Stat Exclude">Stat Exclude</MenuItem>
        </Select>
      ),
    },
  ];

  console.log('users', users);

  return (
    <div style={{ margin: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Admin User Page
      </Typography>
      <Drawer
        anchor="right"
        open={isPanelOpen}
        onClose={() => setIsPanelOpen(false)}
        sx={{
          '& .MuiDrawer-paper': { width: '95vw' },
        }}
      >
        {selectedUser && <UserInfo processUser={selectedUser} />}
      </Drawer>
      {/* Stats Section */}
      <div className="fullWidthContainer">
        <span className="FourPortionFlex fullWidthContainer MinSmall">
          <span className="OnePortionFlex MinSmall">
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'allRegistered' ? 'purpleText bold' : null}
              onClick={() => {
                searchByStat('created_at', new Date(new Date().setYear(2020)), 'allRegistered');
              }}
            >
              {' '}
              Total Registered Users: {userStats.allRegistered}
            </span>
            <br />
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'allExcluded' ? 'purpleText bold' : null}
              onClick={() => {
                searchByStat('created_at', new Date(new Date().setYear(2020)), 'allExcluded');
              }}
            >
              {' '}
              Ignored/Excluded Users: {userStats.allExcluded}
            </span>
          </span>
          <span className="OnePortionFlex MinSmall">
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'todayActive' ? 'purpleText bold' : null}
              onClick={() => searchByStat('last_login', new Date(), 'todayActive')}
            >
              Today's Active Users: {userStats.todayActive}
            </span>
            <br />
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'todayRegistered' ? 'purpleText bold' : null}
              onClick={() => searchByStat('created_at', new Date(), 'todayRegistered')}
            >
              Today's Registered Users: {userStats.todayRegistered}
            </span>
          </span>
          <span className="OnePortionFlex MinSmall">
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'mtdActive' ? 'purpleText bold' : null}
              onClick={() => {
                searchByStat('last_login', new Date(new Date().setDate(1)), 'mtdActive');
              }}
            >
              MTD Active Users: {userStats.mtdActive}
            </span>
            <br />
            <span
              style={{ cursor: 'pointer' }}
              className={currentSearchByStat === 'mtdRegistered' ? 'purpleText bold' : null}
              onClick={() => {
                searchByStat('created_at', new Date(new Date().setDate(1)), 'mtdRegistered');
              }}
            >
              MTD Registered Users: {userStats.mtdRegistered}
            </span>
          </span>
        </span>
      </div>
      {/* Search Section */}
      <IconButton onClick={() => filterView()}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </IconButton>
      {displaySearch ? (
        <Box sx={{ marginTop: 2 }}>
          {filters.map((filter) => (
            <Box
              key={filter.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                marginTop: 1,
              }}
            >
              <Select
                value={filter.field}
                onChange={(e) => handleUpdateFilter(filter.id, 'field', e.target.value)}
                sx={{
                  '& .MuiSelect-select': {
                    paddingRight: 4,
                    paddingLeft: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                  width: 150,
                }}
              >
                <MenuItem value="id">ID</MenuItem>
                <MenuItem value="first_name">First Name</MenuItem>
                <MenuItem value="last_name">Last Name</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="created_at">Created At</MenuItem>
                <MenuItem value="last_login">Last Login</MenuItem>
              </Select>

              <Select
                value={filter.operator}
                onChange={(e) => handleUpdateFilter(filter.id, 'operator', e.target.value)}
                sx={{
                  '& .MuiSelect-select': {
                    paddingRight: 4,
                    paddingLeft: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                  width: 150,
                }}
              >
                <MenuItem value="=">Equals (=)</MenuItem>
                <MenuItem value="LIKE">Contains</MenuItem>
                <MenuItem value=">">Greater Than (&gt;)</MenuItem>
                <MenuItem value="<">Less Than (&lt;)</MenuItem>
              </Select>

              {['created_at', 'last_login'].includes(filter.field) ? (
                <DatePicker
                  value={filter.value ? dayjs(filter.value) : null} // Convert to Day.js object
                  onChange={(newValue) => {
                    const formattedValue = newValue ? newValue.toISOString() : '';
                    handleUpdateFilter(filter.id, 'value', formattedValue);
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" sx={{ width: 250 }} />
                  )}
                />
              ) : (
                <TextField
                  value={filter.value}
                  onChange={(e) => handleUpdateFilter(filter.id, 'value', e.target.value)}
                  placeholder="Enter value"
                  variant="outlined"
                  size="small"
                  sx={{ width: 250 }}
                />
              )}

              <IconButton color="error" onClick={() => handleRemoveFilter(filter.id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
              <IconButton color="error" onClick={() => handleAddFilter()}>
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : null}
      {/* DataGrid */}
      <DataGrid
        rows={users}
        columns={columns}
        rowCount={totalRows}
        paginationMode="server"
        sortingMode="server"
        pageSizeOptions={[10, 25, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        onSortModelChange={handleSortChange}
        sx={{
          marginTop: 4,
          '& .MuiDataGrid-row': {
            transition: 'background-color 0.3s ease-in-out',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#f0f0f0', // Slightly darker hover color
          },
          '& .MuiDataGrid-row.stat_ignore': {
            backgroundColor: '#f5f5f5', // Light gray background for excluded users
          },
        }}
        getRowClassName={(params) => (params.row.stat_ignore ? 'stat_ignore' : '')}
      />

      <StatIgnoreModal
        user={selectedUser}
        isOpen={openModal === 'StatIgnore'}
        onRequestClose={handleStatIgnoreUpdate}
      />
      <ManageCreditsModal
        user={selectedUser}
        isOpen={openModal === 'ManageCredits'}
        onRequestClose={closeManageCreditModal}
      />
      <AdminCancelSubscriptionModal
        user={selectedUser}
        isOpen={openModal === 'CancelSubscription'}
        onRequestClose={setOpenModal}
      />
      <UpgradeMembership
        isOpen={openModal === 'ChangeSubscription'}
        onRequestClose={setOpenModal}
        includeFree={true}
        userDetails={selectedUser}
        currentSubscription={{ id: selectedUser?.subscription_id }}
      />
    </div>
  );
}

export default AdminUserPage;
