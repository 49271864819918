// Import Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// Import Services
import userService from '../../services/userService';

// Setup Modal to Root
Modal.setAppElement('#root');

const StatIgnoreModal = ({ user, isOpen, onRequestClose }) => {
  const [ignoreUser, setIgnoreUser] = useState(false);
  const [reason, setReason] = useState('Staff');

  useEffect(() => {
    if (!user) return onRequestClose(null);

    if (user?.stat_ignore) {
      setIgnoreUser(true);
      setReason(user.stat_ignore || 'Staff');
    }
  }, [user]);

  const handleSave = async () => {
    if (ignoreUser) {
      await userService.updateStatIgnore(user.id, reason);
      onRequestClose(reason);
    } else {
      await userService.updateStatIgnore(user.id, null);
      onRequestClose(null);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Ignore User Modal"
      className="modal"
    >
      {/* Close Button */}
      <div className="close-button" role="button" onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      {/* Modal Header */}
      <div className="modalHeader bold">
        Ignore user {user?.id} ({user?.first_name} {user?.last_name}: {user?.email})?
      </div>

      {/* Modal Content */}
      <div className="modalContent">
        <div className="FullWidthFlex Padded" style={{ paddingBottom: '20px' }}>
          <input
            type="checkbox"
            className="OnePortionFlex"
            checked={ignoreUser}
            onChange={() => setIgnoreUser(!ignoreUser)}
          />
          <span className="FivePortionFlex" style={{ paddingTop: '5px' }}>
            Exclude user from all statistics, reports, and data analysis.
          </span>
        </div>

        {ignoreUser && (
          <div className="FullWidthFlex Padded">
            <span className="OnePortionFlex"></span>
            <FormControl className="ThreePortionFlex">
              <InputLabel id="reason-label">Reason</InputLabel>
              <Select
                label="Reason"
                labelId="reason-label"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <MenuItem value="Staff">Staff</MenuItem>
                <MenuItem value="Duplicate User">Duplicate User</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            <span className="TwoPortionFlex"></span>
          </div>
        )}

        <div className="FullWidthFlex DeepPadded">
          <span className="OnePortionFlex"></span>
          <button
            className="OnePortionFlex buttonClone"
            style={{ marginRight: '70px' }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StatIgnoreModal;
