// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

// Import Components
import UserHistory from './userHistory';
import UserScripts from './userScripts';
import UserCredits from './UserCredits';
import AboutUs from '../../../components/UserComponents/aboutUs';
import Subscription from '../../../components/UserComponents/subscription';
//import CreditUsageList from '../components/subscriptions/creditUsageList';
import Profile from '../../../pages/profile';

// Construct Component
const UserInfo = ({ processUser }) => {
  const [showSection, setShowSection] = useState('Profile');

  return (
    <>
      <div
        className="sizeUp DeepPadded"
        style={{ borderBottom: '1px solid #cccccc', width: '100%' }}
      >
        Now Viewing User {processUser.id}:
        <span className="bold" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          {processUser.first_name} {processUser.last_name}
        </span>
        ({processUser.email})
      </div>
      <div className="fullWidthContainer" style={{ alignItems: 'flex-start' }}>
        <div className="sizeUp linkText Padded" style={{ color: '#0E0F11', width: '115px' }}>
          <div className="Padded" onClick={() => setShowSection('Profile')}>
            Profile
            {showSection === 'Profile' ? (
              <span style={{ paddingLeft: '15px' }}>
                <FontAwesomeIcon className="linkText" icon={faCaretRight} />
              </span>
            ) : null}
          </div>
          <div className="Padded" onClick={() => setShowSection('Scripts')}>
            Scripts
            {showSection === 'Scripts' ? (
              <span style={{ paddingLeft: '15px' }}>
                <FontAwesomeIcon className="linkText" icon={faCaretRight} />
              </span>
            ) : null}
          </div>
          <div className="Padded" onClick={() => setShowSection('History')}>
            History
            {showSection === 'History' ? (
              <span style={{ paddingLeft: '15px' }}>
                <FontAwesomeIcon className="linkText" icon={faCaretRight} />
              </span>
            ) : null}
          </div>
          <div className="Padded" onClick={() => setShowSection('Credits')}>
            Credits
            {showSection === 'Credits' ? (
              <span style={{ paddingLeft: '15px' }}>
                <FontAwesomeIcon className="linkText" icon={faCaretRight} />
              </span>
            ) : null}
          </div>
        </div>

        <div className="FourPortionFlex" style={{ borderLeft: '1px solid #cccccc' }}>
          {showSection === 'History' ? (
            <UserHistory processUser={processUser} />
          ) : showSection === 'Scripts' ? (
            <UserScripts processUser={processUser} />
          ) : showSection === 'Credits' ? (
            <UserCredits openUser={processUser} level={'user'} />
          ) : (
            <div style={{ marginLeft: '20px' }}>
              <Profile userDetails={processUser} isStaff={true} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserInfo;
