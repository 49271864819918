// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Import Components
import Select from '../../components/Elements/Select';
import Sorter from '../components/sortOrder';
import SearchElement from '../components/searchElement';
import UserInfo from '../components/user/userInfo';
import AdminUserDataGrid from './userDataGrid';

// Import Services
import AdminUserSvc from '../services/adminUserService';
import AdminUtils from '../services/utils';

function AdminUserPage() {
  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      <AdminUserDataGrid />
    </div>
  );
}

export default AdminUserPage;
