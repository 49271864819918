// Import Libraries
import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Import Services
import subscriptionService from '../../../services/subscriptionService';

// Setup Modal to Root
Modal.setAppElement('#root');

const AdminCancelSubscriptionModal = ({ user, isOpen, onRequestClose }) => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!user?.id || !user?.subscription_id) {
      setMessage('Invalid user or subscription ID.');
      return;
    }

    setMessage(null);
    setLoading(true);

    try {
      const cancelSubscription = await subscriptionService.cancelSubscription(
        user.subscription_id,
        user.id
      );

      if (cancelSubscription) {
        onRequestClose();
      } else {
        setMessage('Failed to cancel subscription.');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Cancel User Subscription Modal"
      className="modal"
    >
      {/* Close Button */}
      <div className="close-button" role="button" onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      {/* Modal Header */}
      <div className="modalHeader bold">
        Cancel Subscription for User {user?.id} ({user?.first_name} {user?.last_name}: {user?.email}
        )?
      </div>

      {/* Modal Content */}
      <div className="modalContent">
        <p className="FullWidthFlex Padded">
          Are you sure? This will not immediately close the subscription, however, it will cancel
          the next billing cycle. They will maintain their available credit balance and they will
          have access to pro features until the end of the current billing cycle. After that, they
          will retain their available credit balance, but will no longer have access to pro
          features.
        </p>

        {/* Error Message Display */}
        {message && (
          <p style={{ color: 'red', textAlign: 'center', marginBottom: '15px' }}>{message}</p>
        )}

        <div className="FullWidthFlex DeepPadded">
          <button
            className="OnePortionFlex buttonClone"
            style={{ marginRight: '70px' }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? 'Cancelling...' : 'Cancel Subscription'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminCancelSubscriptionModal;
