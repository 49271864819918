const AdminUtils = {
  FormatDateWithTime: function (timestamp) {
    const date = new Date(timestamp);
    const offset = 4 * 60 * 60 * 1000;
    const adjustedDate = new Date(date.getTime() - offset);
    return new Date(adjustedDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  },

  FormatDate: function (timestamp) {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  },
};

export default AdminUtils;
